var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'},[_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"mb-2 title black--text"},[_vm._v("List User pada "),_c('b',[_vm._v(_vm._s(_vm.company_detail.name))])]),_c('v-row',{staticClass:"mt-3",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"placeholder":"Search User","outlined":"","dense":"","autocomplete":"off","prepend-inner-icon":"mdi-magnify","clearable":"","color":"#F05326","hide-details":true,"readonly":_vm.process.run},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetch()},"click:clear":function($event){_vm.searching = ''}},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"rounded-lg text-capitalize white--text",attrs:{"depressed":"","height":"40","color":"primary","loading":_vm.process.run},on:{"click":_vm.showDialogAddUserCompany}},[_vm._v(" Add User ")])],1)],1),_c('div',{staticClass:"mt-3 mb-8"},[_c('v-skeleton-loader',{staticClass:"ma-auto",attrs:{"loading":_vm.$store.state.process.run,"tile":false,"transition":"scale-transition","type":"table-tbody"}},[_c('v-data-table',{staticClass:"table-list pa-0",attrs:{"hide-default-footer":"","fixed-header":"","loading":_vm.$store.state.process.run || _vm.process.run,"headers":_vm.headers,"items":_vm.user_object.list,"items-per-page":_vm.user_object.limit,"page":_vm.user_object.page,"server-items-length":_vm.user_object.total,"item-key":"id","footer-props":{
            itemsPerPageText: 'Data per halaman',
            'items-per-page-options': [5, 10, 20, 50, 100, 200, 350],
          }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.user_object, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.user_object, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.user_object, "page", $event)}},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
          var props = ref.props;
return [_c('Pagination',{attrs:{"model":props},on:{"reload":function($event){return _vm.fetch(_vm.page)},"changeLimit":function($event){_vm.limit = $event;
            _vm.fetch();},"changePage":function($event){_vm.page = $event;
            _vm.fetch($event);}}})]}},{key:"item",fn:function(props){return [_c('tr',{staticClass:"px-3 mx-3 box-shadow"},[_c('td',{staticClass:"text-start white"},[_vm._v(" "+_vm._s(props.item.fullname)+" ")]),_c('td',{staticClass:"text-start white"},[_vm._v(" "+_vm._s(props.item.email)+" ")]),_c('td',{staticClass:"text-left white"},[_vm._v(" "+_vm._s(_vm._f("emptyData")(props.item.phone))+" ")]),_c('td',{staticClass:"text-center white"},[_c('v-menu',{attrs:{"offset-y":"","nudge-left":"125"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical-circle-outline")])],1)]}}],null,true)},[_c('v-list',{staticClass:"rounded-lg pa-0",attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.showDialogUpdateUserCompany(props.item)}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"20","color":"blue"}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Update User")])],1)],1)],1)],1)],1)])]}}])})],1)],1),_c('DialogAddUserCompany',{ref:"dialog_add_user_company",attrs:{"company_id":_vm.company_id},on:{"action":_vm.fetch}}),_c('DialogChangePassword',{ref:"dialog_change_password"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }