<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

    <div class="mt-5">
      <div class="mb-2 title black--text">List User pada <b>{{ company_detail.name }}</b></div>

      <v-row justify="space-between" class="mt-3">
        <v-col cols="4">
          <v-text-field placeholder="Search User" outlined dense autocomplete="off" prepend-inner-icon="mdi-magnify"
            clearable color="#F05326" class="rounded-lg" :hide-details="true" :readonly="process.run"
            v-model="searching" v-on:keyup.enter="fetch()" @click:clear="searching = ''">
          </v-text-field>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-btn depressed height="40" color="primary" class="rounded-lg text-capitalize white--text"
            :loading="process.run" @click="showDialogAddUserCompany">
            Add User
          </v-btn>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader class="ma-auto" :loading="$store.state.process.run" :tile="false"
          transition="scale-transition" type="table-tbody">
          <v-data-table class="table-list pa-0" hide-default-footer fixed-header
            :loading="$store.state.process.run || process.run" :headers="headers" :items="user_object.list"
            :items-per-page.sync="user_object.limit" :page.sync="user_object.page"
            :server-items-length="user_object.total" item-key="id" :footer-props="{
              itemsPerPageText: 'Data per halaman',
              'items-per-page-options': [5, 10, 20, 50, 100, 200, 350],
            }">
            <template slot="footer" slot-scope="{ props }">
              <Pagination @reload="fetch(page)" @changeLimit="
                limit = $event;
              fetch();
              " @changePage="
                page = $event;
              fetch($event);
              " :model="props" />
            </template>
            <template slot="item" slot-scope="props">
              <tr class="px-3 mx-3 box-shadow">
                <td class="text-start white">
                  {{ props.item.fullname }}
                </td>
                <td class="text-start white">
                  {{ props.item.email }}
                </td>
                <td class="text-left white">
                  {{ props.item.phone | emptyData }}
                </td>
                <!-- <td class="text-left white text-capitalize">
                  {{ props.item.gender.text }}
                </td> -->
                <!-- <td class="text-center white text-capitalize">
                  {{ props.item.status }}
                </td> -->
                <td class="text-center white">
                  <v-menu offset-y nudge-left="125">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="grey" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense class="rounded-lg pa-0">
                      <v-list-item @click.stop="showDialogUpdateUserCompany(props.item)">
                        <v-list-item-icon class="mr-4">
                          <v-icon size="20" color="blue">mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Update User</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- <v-list-item  @click.stop="showDialogChangePassword(props.item.fullname, props.item.id)">
                        <v-list-item-icon class="mr-4">
                          <v-icon size="20" color="error">mdi-key-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Change Password</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item> -->
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <DialogAddUserCompany ref="dialog_add_user_company" :company_id="company_id" @action="fetch">
      </DialogAddUserCompany>
      <DialogChangePassword ref="dialog_change_password"></DialogChangePassword>
    </div>
  </div>
</template>

<script>
import { get } from "@/service/Axios";
import Pagination from "@/components/Pagination.vue";
import DialogAddUserCompany from "./components/DialogAddUserCompany.vue";
import DialogChangePassword from "./components/DialogChangePassword.vue";

export default {
  components: {
    Pagination,
    DialogAddUserCompany,
    DialogChangePassword,
  },
  data() {
    return {
      company_id: "",
      company_detail: {},
      user_object: {},
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
      },
      searching: '',
      process: {
        run: false,
      },
      headers: [
        { text: "Name", align: "start", value: "fullname", sortable: false },
        { text: "Email", align: "start", value: "email", sortable: false },
        { text: "Phone Number", align: "start", value: "phone_number", sortable: false },
        // { text: "Company Name", align: "start", value: "company.name", sortable: false },
        { text: "Action", align: "center", value: "status", sortable: false },
      ],
    }
  },
  props: {
    // company_id: String,
  },
  watch: {
    "selected.pagination": function (val) {
      this.fetch(val + 1);
    },
    searching: _.debounce(function (newVal) {
      this.searching = newVal;
      this.fetch();
    }, 500),
  },
  mounted() {
    if (this.$route.params.company_id) {
      this.getDetailCompany();
    }
  },
  methods: {
    async getDetailCompany() {
      this.process.run = true;

      await get(`company/v1/superuser/detail/${this.$route.params.company_id}`).then(
        (response) => {
          let res = response.data;
          this.company_detail = res.data;
          this.company_id = res.data.id;
          this.process.run = false;
          this.$store.state.process.run = false;

          this.fetch();
        }
      );
    },
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    async fetch(paging) {
      this.process.run = true;
      await get(`users/v1/superuser/member`, {
        params: {
          company_id: this.company_id,
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          fullname: this.searching,
          // q: this.searching,
        },
      }).then((response) => {
        let res = response.data;
        this.user_object = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
      });
    },
    showDialogAddUserCompany() {
      this.$refs.dialog_add_user_company.showDialog('create')
    },
    showDialogUpdateUserCompany(items) {
      this.$refs.dialog_add_user_company.showDialog('update')
      this.$refs.dialog_add_user_company.setFormDetail(items)
    },
    showDialogChangePassword(name, id) {
      this.$refs.dialog_change_password.showDialog(name, id);
    },
  }
}
</script>
