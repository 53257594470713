<template>
  <div>
    <v-dialog v-model="show_dialog" persistent scrollable width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 d-flex primary">
          <div class="white--text text-capitalize">
            {{ form.id ? "Update" : "Add" }} User Company
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="hideDialog()" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="formAddUser">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="User Fullname"
                  outlined
                  dense
                  color="primary"
                  hide-details="auto"
                  :rules="rules.input"
                  v-model="form.fullname"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  outlined
                  dense
                  color="primary"
                  hide-details="auto"
                  :rules="[...rules.input, ...rules.email]"
                  v-model="form.email"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Phone Number"
                  outlined
                  dense
                  type="number"
                  color="primary"
                  hide-details="auto"
                  :rules="rules.phone_number"
                  v-model="form.phone"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="isShowPoB" v-show="form.id !== ''" cols="12">
                <v-text-field
                  label="Place of Birth"
                  outlined
                  dense
                  type="text"
                  color="primary"
                  hide-details="auto"
                  :rules="form.id !== '' ? rules.input : []"
                  v-model="form.place_of_birth"
                >
                </v-text-field>
              </v-col>
              <v-col v-show="form.id !== ''" cols="12">
                <v-menu
                  v-model="menu_date_of_birth"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatedDateOfBirth"
                      clearable
                      outlined
                      dense
                      hide-details="auto"
                      label="Date of Birth"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="form.date_of_birth = null"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date_of_birth"
                    @change="menu_date_of_birth = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col v-if="isShowAddress" v-show="form.id !== ''" cols="12">
                <v-textarea
                  label="Address"
                  outlined
                  dense
                  type="text"
                  color="primary"
                  hide-details="auto"
                  rows="3"
                  :rules="form.id !== '' ? rules.input : []"
                  v-model="form.meta.alamat_rumah"
                >
                </v-textarea>
              </v-col>
              <v-col v-if="isShowPostalCode" v-show="form.id !== ''" cols="12">
                <v-text-field
                  label="Postal code"
                  outlined
                  dense
                  type="text"
                  color="primary"
                  hide-details="auto"
                  :rules="form.id !== '' ? rules.input : []"
                  v-model="form.meta.code"
                >
                </v-text-field>
              </v-col>
              <v-col v-show="form.id === ''" cols="12">
                <v-text-field
                  label="Password"
                  outlined
                  dense
                  color="primary"
                  autocomplete="off"
                  hide-details="auto"
                  :type="show_password_one ? 'text' : 'password'"
                  :append-icon="show_password_one ? 'mdi-eye-off' : 'mdi-eye'"
                  :rules="form.id === '' ? rules.password : []"
                  :error-messages="
                    error_message.password ? error_message.password : ''
                  "
                  v-model="form.password"
                  @click:append="show_password_one = !show_password_one"
                >
                </v-text-field>
              </v-col>
              <v-col v-show="form.id === ''" cols="12">
                <v-text-field
                  label="Confirm Password"
                  outlined
                  dense
                  color="primary"
                  autocomplete="off"
                  hide-details="auto"
                  :type="show_password_two ? 'text' : 'password'"
                  :append-icon="show_password_two ? 'mdi-eye-off' : 'mdi-eye'"
                  :rules="form.id === '' ? rules.password : []"
                  :error-messages="
                    error_message.confirm_password
                      ? error_message.confirm_password
                      : '' || check_confirm_password
                  "
                  v-model="form.confirm_password"
                  @click:append="show_password_two = !show_password_two"
                  @change="checkConfirmPassword"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-2"
            class="mr-1 text-capitalize"
            depressed
            width="120"
            height="40"
            :loading="btn_loading"
            @click="hideDialog()"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="white--text text-capitalize"
            depressed
            width="120"
            height="40"
            :loading="btn_loading"
            @click="saveUserCompany()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar ref="snackbar"></Snackbar>
  </div>
</template>

<script>
import { put, post, get } from "@/service/Axios";
import Snackbar from "@/components/Snackbar.vue";
import moment from "moment";

export default {
  components: { Snackbar },
  data() {
    return {
      show_dialog: false,
      show_password_one: false,
      show_password_two: false,
      btn_loading: false,
      menu_date_of_birth: false,

      searching: "",
      rules: {
        input: [(v) => !!v || "This field is required"],
        email: [
          (v) =>
            !v ||
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
        ],
        phone_number: [
          (v) => !!v || "This field is required",
          (v) =>
            (v && v.length >= 10) ||
            "Phone number can't less than 10 characters",
        ],
        password: [
          (v) => !!v || "This field is required",
          (v) =>
            (v && v.length >= 7) || "Password can't less than 7 characters",
          // password must contain at least one number, one lowercase, one uppercase letter, one special character
          (v) =>
            (v &&
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{":;'?/>.<,])(?=.*[^a-zA-Z0-9])/.test(
                v
              )) ||
            "Password must contain at least one number, one lowercase, one uppercase letter, one special character",
        ],
      },
      form: {
        id: "",
        company_id: "",
        fullname: "",
        email: "",
        phone: "",
        password: "",
        place_of_birth: "",
        date_of_birth: "",
        confirm_password: "",
        meta: {
          code: "",
          alamat_rumah: "",
        },
      },
      error_message: {},
      check_confirm_password: "",
      companySelected: {
        list: [],
      },
    };
  },
  props: {
    company_id: String,
    formType: String,
    fullname: String,
    email: String,
    phone: String,
  },
  watch: {
    searching: _.debounce(function() {
      this.getCompanyList();
    }, 500),
  },
  async mounted() {
    this.companySelected = await this.getCompanyList();
  },
  computed: {
    formatedDateOfBirth() {
      return this.form.date_of_birth
        ? moment(this.form.date_of_birth).format("DD MMMM YYYY")
        : "";
    },
    isShowPoB() {
      const keys = [
        "1a65334b-43da-4fe6-bbc5-53e7759508ee", // Kreasi belajar / tryout pro
        "9911643b-51a8-4bbc-b9ef-fb66ee36f614", // yec / bakat
        "2274880c-17cf-4361-bb01-b48d733d85af", // LP Profesi / Kayana Digdaya
        "8b035130-df5c-4ef6-82fa-b4fe0c146f89", // TPA Amikom
      ];
      return !keys.includes(this.$route.params.company_id);
    },
    isShowAddress() {
      const keys = [
        "1a65334b-43da-4fe6-bbc5-53e7759508ee", // Kreasi belajar / tryout pro
        "9911643b-51a8-4bbc-b9ef-fb66ee36f614", // yec / bakat
        "2274880c-17cf-4361-bb01-b48d733d85af", // LP Profesi / Kayana Digdaya
        "8b035130-df5c-4ef6-82fa-b4fe0c146f89", // TPA Amikom
      ];
      return !keys.includes(this.$route.params.company_id);
    },
    isShowPostalCode() {
      const keys = [
        "1a65334b-43da-4fe6-bbc5-53e7759508ee", // Kreasi belajar / tryout pro
        "9911643b-51a8-4bbc-b9ef-fb66ee36f614", // yec / bakat
        "2274880c-17cf-4361-bb01-b48d733d85af", // LP Profesi / Kayana Digdaya
        "8b035130-df5c-4ef6-82fa-b4fe0c146f89", // TPA Amikom
      ];
      return !keys.includes(this.$route.params.company_id);
    },
  },
  methods: {
    async getCompanyList() {
      const { data } = await get(`company/v1/superuser/list`, {
        params: {
          limit: 10,
          page: 1,
          sort: "created_at",
          dir: "asc",
          q: this.searching,
        },
      });

      return data.data;
    },
    async getRoles() {
      const { data } = await get("users/v1/superuser/role/list", {
        query: {
          company_id: this.company_id,
        },
      });
      return data?.data;
    },
    setFormDetail(data) {
      this.form = {
        id: data.id,
        company_id: data.company_id,
        fullname: data.fullname,
        email: data.email,
        phone: data.phone,
        password: data.password,
        place_of_birth: data.place_of_birth,
        date_of_birth: data.date_of_birth,
        confirm_password: data.confirm_password,
        meta: {
          code: data.meta?.code,
          alamat_rumah: data.meta?.alamat_rumah,
        },
      };

      this.$refs.formAddUser.resetValidation();
    },
    showDialog(state) {
      this.show_dialog = true;
      this.form.company_id = this.company_id;
      if (state == "create") {
        this.setFormDetail({
          id: "",
          company_id: this.company_id,
          fullname: "",
          email: "",
          phone: "",
          password: "",
          place_of_birth: "",
          date_of_birth: "",
          confirm_password: "",
          meta: {
            code: "",
            alamat_rumah: "",
          },
        });
      }

      // this.$refs.formAddUser.resetValidation()
    },
    hideDialog() {
      this.show_dialog = false;
      this.btn_loading = false;
      this.$refs.formAddUser.reset();
      this.$refs.formAddUser.resetValidation();
      this.error_message = {};
    },
    checkConfirmPassword() {
      if (this.form.confirm_password != this.form.password) {
        this.check_confirm_password =
          "Confirm Password different with Password";
      } else {
        this.check_confirm_password = "";
      }
    },
    async saveUserCompany() {
      if (this.$refs.formAddUser.validate()) {
        this.btn_loading = true;
        if (this.form.id) {
          await put(`users/v1/superuser/member/update/${this.form.id}`, {
            data: {
              fullname: this.form.fullname,
              email: this.form.email,
              phone_number: this.form.phone,
              status: "active",
              place_of_birth: this.form.place_of_birth,
              date_of_birth: this.form.date_of_birth,
              meta: {
                code: this.form.meta.code,
                alamat_rumah: this.form.meta.alamat_rumah,
              },
            },
          })
            .then((response) => {
              let res = response.data;
              if (res.status == 200) {
                this.hideDialog();
                // reset form
                this.form = {
                  id: "",
                  company_id: this.company_id,
                  fullname: "",
                  email: "",
                  phone: "",
                  password: "",
                  place_of_birth: "",
                  date_of_birth: "",
                  confirm_password: "",
                  meta: {
                    code: "",
                    alamat_rumah: "",
                  },
                };
                this.$refs.snackbar.open("#4CAF50", `Update User Successfully`);
                this.$emit("action");
              } else {
                this.hideDialog();
                this.$refs.snackbar.open("#e53935", `Add User Failed`);
              }
            })
            .catch(() => {
              this.error_message = this.$store.state.ContentValidation;
              this.btn_loading = false;
            });
        } else {
          await post(`users/v1/superuser/member/create`, {
            data: {
              company_id: this.form.company_id,
              fullname: this.form.fullname,
              email: this.form.email,
              phone_number: this.form.phone,
              place_of_birth: this.form.place_of_birth,
              date_of_birth: this.form.date_of_birth,
              password: this.form.password,
              confirm_password: this.form.confirm_password,
              status: "active",
            },
          })
            .then((response) => {
              let res = response.data;
              if (res.status == 200) {
                this.hideDialog();
                // reset form
                this.form = {
                  id: "",
                  company_id: this.company_id,
                  fullname: "",
                  email: "",
                  phone: "",
                  password: "",
                  place_of_birth: "",
                  date_of_birth: "",
                  confirm_password: "",
                  meta: {
                    code: "",
                    alamat_rumah: "",
                  },
                };
                this.$refs.snackbar.open("#4CAF50", `Add User Successfully`);
                this.$emit("action");
              } else {
                this.hideDialog();
                this.$refs.snackbar.open("#e53935", `Add User Failed`);
              }
            })
            .catch(() => {
              this.error_message = this.$store.state.ContentValidation;
              this.btn_loading = false;
            });
        }
      }
    },
  },
};
</script>
